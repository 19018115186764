<template>
  <div class="guide-cnt1">
    <h6 class="h6">在线支付</h6>
    <div class="cnt">
      <p class="p">1、预存余额支付：微微定会员可提前对账户进行充值，在支付订单时，可直接选择“预存余额支付”即可。若预存余额不足，可选择扣除全部预存余额，剩余金额可通过微信支付、支付宝支付；</p>
      <img class="img" src="../../assets/img/guide/olpy.jpg">
      <p class="p">2、支付宝支付：微微定支持支付宝支付，微微定会员选择“支付宝支付”可立即完成支付，并即时到账；</p>
      <img class="img" src="../../assets/img/guide/olpy1.jpg">
      <p class="p">2、微信支付：微微定支持微信支付，微微定会员选择“微信支付”可立即完成支付，并即时到账。</p>
      <img class="img" src="../../assets/img/guide/olpy2.jpg">
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt1',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt1 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;
    }
    .img {
      display: block;width: 100%;margin:10px 0 20px 0px;
    }
  }
}
</style>
